.login {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.left-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2rem 11rem;
  background-color: var(--positive);
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.right-column {
  background-color: var(--primary);
  overflow: hidden;
}

.atom {
  position: relative;
  opacity: 0.6;

  img {
    top: -115px;
    left: 25px;
    position: absolute;
    width: 175%;
  }
}