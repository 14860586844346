@import './scss/default.scss';

$body-bg: $light;
$body-color: $dark;
$enable-rounded: true;

/* Checkbox variables */
$custom-control-indicator-checked-bg: $info;
$custom-control-indicator-checked-disabled-bg: rgba($info, .5);

$input-group-addon-bg: transparent;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "danger": $danger,
  "info": $info,
  "warning": $warning,
  "light": $light,
  "dark": $dark,
  "positive": $positive,
  "negative": $negative,
  "gray-1": $gray-1,
  "gray-2": $gray-2
);

* {
  font-family: 'Lato', sans-serif;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 700 !important;
}

@import '~bootstrap/scss/bootstrap';

.custom-btn-outline-primary {
  @include button-outline-variant($primary, $positive, $primary, $primary);
  @include button-size(.25rem, 2rem, small, 1rem, 1rem);
  &.btn-lg {
    @include button-size(.5rem, 2rem, medium, 1rem, 1rem);
  }
}

.custom-control-input {
  &:checked ~ .custom-control-label::before {
    background-color: $primary;
    border-color: $primary;
  }
}
