.base-table {
  background-color: var(--positive);

  tr {
    border-bottom: 1px solid var(--gray-1);
  }
  th {
    background-color: var(--light);
    text-transform: uppercase;
    font-size: small;
    font-weight: 400;
    color: var(--gray);
  }
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30rem;
    font-size: small;
    vertical-align: middle !important;

    a {
      color: var(--dark);

      &:hover {
        color: var(--primary);
        font-weight: 700;
      }
    }
  }
  .column {
    &--numeric {text-align: right;}
  }
}
