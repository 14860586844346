$primary: #FFAC33;
$secondary: #30465A;
$success: #28A745;
$danger: #D72C2C;
$info: #1F9ED9;
$warning: #F9B950;
$light: #F9F9F9;
$dark: #4D4D45;
$positive: #FFFFFF;
$negative: #222222;
$gray-1: #f3f3f3;
$gray-2: #464645;
