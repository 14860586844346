.totals-row {
  font-weight: 700;
  background-color: var(--gray-1);
}

.download-btn {
  position: absolute;
  right: 3rem;
  margin-top: -2rem;
}
