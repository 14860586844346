.sidebar {
  border: 1px solid var(--light);
  background-color: var(--positive);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);

  .brand {
    width: 100%;
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-item {
    display: flex;
    align-items: center;
    height: 64px;
    line-height: 64px;
    border-right: 3px solid var(--warning);
    cursor: pointer;
    font-size: small;
    font-weight: 700;

      &.active,
      &:hover {
       background-color: var(--primary);
       color: var(--positive);
     }
  }
}
